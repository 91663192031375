import { createSelector } from '@ngrx/store';
import {
  ActionTypes,
  TypeAction
} from './profile.actions';
import { IRootState } from '../../../store';
import { IOption } from '../../../shared/components/select';
import {
  createSelectModelFromAnyArray,
  createSelectModelFromStringArray,
  ICreateSelectModelPropsSetting
} from '../../../shared/components/select/models/createSelectModel';
import { ValidationTypesEnum } from '../../../core/services/employee/employee.service';

export enum FieldsAsyncValidationEnum {
  primaryPhone,
  alternatePhone,
  emergencyPrimaryPhone,
  emergencyAlternatePhone,
}

export interface IFieldsLoadingProgress {
  primaryPhone: boolean;
  alternatePhone: boolean;
  emergencyPrimaryPhone: boolean;
  emergencyAlternatePhone: boolean;
}

export interface IProfileOptions {
  phoneType: any[];
  college: any[];
  relationship: any[];
  state: any[];
}

export interface ISelectModels {
  phoneType: IOption[];
  college: IOption[];
  relationship: IOption[];
  state: IOption[];
}

export interface IRecruitProfile {
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  middleName: string;
  experience: string;
  employeeId: number | null;
  preferredPhoneNumber: {
    phoneNumber: string;
    typeId: number;
  };
  alternatePhoneNumber: {
    phoneNumber: string;
    typeId: number;
  };
  email: string;
  school: string;
  imageUrl: string;
  emergencyContact: {
    firstName: string;
    lastName: string;
    primaryPhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    alternatePhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    relationship: string | null;
    relationshipId: number | null;
  };
  address: {
    zip: string;
    addressLine: string;
    addressLine2: string;
    city: string;
    state: string | null;
    province: string | null;
  };
  manager: {
    employeeId: number | null;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface IEditModes {
  personalInfo: boolean;
  emergencyContacts: boolean;
}

export enum ProfileEditModes {
  personalInfo,
  emergencyContacts
}

export interface IRecruitProfileFormData {
  email: string;
  school: string;
  primaryPhone: string;
  primaryPhoneType: number | null;
  alternatePhone: string;
  alternatePhoneType: number | null;
  zip: string;
  addressLine: string;
  addressLine2: string;
  city: string;
  countryState: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPrimaryPhone: string;
  emergencyAlternatePhone: string;
  emergencyRelationshipId: string;
  emergencyPrimaryPhoneType: number | null;
  emergencyAlternatePhoneType: number | null;
}

export interface IState {
  profile: IRecruitProfile | null;
  formData: IRecruitProfileFormData;
  selectOptions: IProfileOptions;
  college: {
    query: string;
  };
  queryState: string;
  editMode: IEditModes;
  phonesValidity: {
    primaryPhone: ValidationTypesEnum;
    alternatePhone: ValidationTypesEnum;
    emergencyPrimaryPhone: ValidationTypesEnum;
    emergencyAlternatePhone: ValidationTypesEnum;
  };
  fieldsLoadingProgress: IFieldsLoadingProgress;
  isInitialFetched: boolean;
}

const initialState: IState = {
  profile: {
    firstName: '',
    preferredFirstName: '',
    lastName: '',
    middleName: '',
    experience: '',
    employeeId: null,
    preferredPhoneNumber: {
      phoneNumber: '',
      typeId: null
    },
    alternatePhoneNumber: {
      phoneNumber: '',
      typeId: null
    },
    email: '',
    school: '',
    imageUrl: '',
    emergencyContact: {
      firstName: '',
      lastName: '',
      primaryPhoneNumber: null,
      alternatePhoneNumber: null,
      relationship: null,
      relationshipId: null
    },
    address: {
      zip: '',
      addressLine: '',
      addressLine2: '',
      city: '',
      state: null,
      province: null
    },
    manager: {
      employeeId: null,
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  formData: {
    email: '',
    school: '',
    primaryPhone: '',
    primaryPhoneType: null,
    alternatePhone: '',
    alternatePhoneType: null,
    zip: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    countryState: '',
    emergencyFirstName: '',
    emergencyLastName: '',
    emergencyPrimaryPhone: '',
    emergencyAlternatePhone: '',
    emergencyRelationshipId: '',
    emergencyPrimaryPhoneType: null,
    emergencyAlternatePhoneType: null,
  },
  selectOptions: {
    phoneType: [],
    college: [],
    relationship: [],
    state: []
  },
  college: {
    query: ''
  },
  queryState: '',
  editMode: {
    personalInfo: false,
    emergencyContacts: false
  },
  phonesValidity: {
    primaryPhone: ValidationTypesEnum.initial,
    alternatePhone: ValidationTypesEnum.initial,
    emergencyAlternatePhone: ValidationTypesEnum.initial,
    emergencyPrimaryPhone: ValidationTypesEnum.initial,
  },
  fieldsLoadingProgress: {
    primaryPhone: false,
    alternatePhone: false,
    emergencyAlternatePhone: false,
    emergencyPrimaryPhone: false
  },
  isInitialFetched: false
};

export function profileReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_RECRUIT_PROFILE: {
      return Object.assign({}, state, { profile: payload, isInitialFetched: true });
    }
    case ActionTypes.PRE_POPULATE_PROFILE_FORM: {
      return Object.assign({}, state, { formData: {
          ...state.formData,
          ...payload
        } });
    }
    case ActionTypes.PROFILE_SEARCH_SCHOOL: {
      const { college }: IState = state;
      const result = Object.assign({}, college, {
        query: payload
      });

      return Object.assign({}, state, { college: result });
    }
    case ActionTypes.PROFILE_SEARCH_STATE: {
      return Object.assign({}, state, { queryState: payload });
    }
    case ActionTypes.PROFILE_UPDATE_FORM_DATA: {
      const { formData }: IState = state;
      const result = Object.assign({}, formData, { [payload.fieldId]: payload.value });

      return Object.assign({}, state, { formData: result });
    }
    case ActionTypes.SET_SELECT_OPTIONS: {
      return Object.assign({}, state, { selectOptions: payload });
    }
    case ActionTypes.SET_EDIT_MODE: {
      return Object.assign({}, state, { editMode: {
          ...state.editMode,
          [ProfileEditModes[payload.group]]: payload.state
        } });
    }
    case ActionTypes.PROFILE_COMPLETE_SUBMISSION: {
      const {
        profile,
        formData,
        selectOptions
      }: IState = state;
      const result = getUpdatedProfile(profile, formData, selectOptions, payload);

      return Object.assign({}, state, { profile: result });
    }

    case ActionTypes.SET_FIELD_LOADING_STATE: {
      const { fieldsLoadingProgress }: IState = state;
      const changes = {
        ...fieldsLoadingProgress,
        [FieldsAsyncValidationEnum[payload.field]]: payload.isLoading
      };

      return Object.assign({}, state, { fieldsLoadingProgress: changes });
    }

    case ActionTypes.SET_PHONE_VALIDATION: {
      const { phonesValidity }: IState = state;
      const changes = {
        ...phonesValidity,
        [FieldsAsyncValidationEnum[payload.field]]: payload.valid
      };

      return Object.assign({}, state, { phonesValidity: changes });
    }

    default: {
      return state;
    }
  }
}

export const getRecruitProfile = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ profile }: IState): IRecruitProfile => profile
);

export const getRecruitProfileFormData = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ formData }: IState): IRecruitProfileFormData => ({
    ...formData
  })
);

export const getSubmitFormData = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ formData, selectOptions }: IState): IRecruitProfileFormData => ({
    ...formData,
    school: selectOptions.college.find((college) =>
      Number(college.value) === Number(formData.school))
  })
);

export const getSelectModels = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ selectOptions }: IState): ISelectModels => {
    const selectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'value',
      labelKey: 'text'
    };

    return Object.keys(selectOptions).reduce((acc, key) => {
      acc[key] = typeof selectOptions[key][0] !== 'object' ?
        createSelectModelFromStringArray(selectOptions[key]) :
        createSelectModelFromAnyArray(selectOptions[key], selectSetting);

      return acc;
    }, Object.assign({}, selectOptions));
  }
);

export const getFilteredColleges = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  (state: IState): IOption[] => {
    const {
      selectOptions: { college },
      college: { query }
    } = state;

    return college
      .filter((school) => school.text
        .toLowerCase()
        .includes(query.toLowerCase()))
      .map((school) => ({
        value: school.value,
        label: school.text
      }));
  }
);

export const getFilteredStates = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  (state: IState): IOption[] => {
    const {
      selectOptions: { state: countryStates },
      queryState
    } = state;

    return countryStates
      .filter((countryState) => countryState.text
        .toLowerCase()
        .includes(queryState.toLowerCase()))
      .map(({ value, text }) => ({ value, label: text }));
  }
);

export const getEditMode = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ editMode }: IState) => editMode
);

export const getIsFieldLoading = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ fieldsLoadingProgress }: IState) => fieldsLoadingProgress[FieldsAsyncValidationEnum[field]]
);

export const getPhoneValidity = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ phonesValidity }: IState) => phonesValidity[FieldsAsyncValidationEnum[field]]
);

export const getIsProfileDataFetched = createSelector(
  ({ recruitProfile }: IRootState): IState => recruitProfile,
  ({ isInitialFetched }: IState): boolean => isInitialFetched
);

function getUpdatedProfile(
  profile: IRecruitProfile,
  formData: IRecruitProfileFormData,
  { relationship, state, college: colleges },
  group: ProfileEditModes
) {
  const school = colleges.find((college) =>
    Number(college.value) === Number(formData.school));

  const relationshipText = relationship.find((rel) =>
    Number(rel.value) === Number(formData.emergencyRelationshipId)).text;

  const groups = {
    [ProfileEditModes.personalInfo]: {
      preferredPhoneNumber: {
        phoneNumber: formData.primaryPhone,
        typeId: formData.primaryPhoneType
      },
      alternatePhoneNumber: {
        phoneNumber: formData.alternatePhone,
        typeId: formData.alternatePhoneType,
      },
      email: formData.email,
      address: {
        zip: formData.zip,
        addressLine: formData.addressLine,
        addressLine2: formData.addressLine2,
        city: formData.city,
        state: profile.address.state ?
          state.find((item) => formData.countryState === item.value).text : null,
        province: profile.address.province ?
          state.find((item) => formData.countryState === item.value).text : null
      }
    },
    [ProfileEditModes.emergencyContacts]: {
      emergencyContact: {
        firstName: formData.emergencyFirstName,
        lastName: formData.emergencyLastName,
        primaryPhoneNumber: {
          phoneNumber: formData.emergencyPrimaryPhone,
          typeId: formData.emergencyPrimaryPhoneType,
        },
        alternatePhoneNumber: formData.emergencyAlternatePhone ? {
          phoneNumber: formData.emergencyAlternatePhone,
          typeId: formData.emergencyAlternatePhoneType,
        } : null,
        relationship: relationshipText,
        relationshipId: formData.emergencyRelationshipId
      }
    }
  };

  const updatedProfile = Object.assign({}, profile, groups[group]);

  if (school) {
    Object.assign(updatedProfile, { school: school.text });
  }

  return updatedProfile;
}
