import { Action } from '@ngrx/store';
import { type } from '../../../util';
import {
  FieldsAsyncValidationEnum,
  IRecruitProfileFormData,
  ProfileEditModes
} from './profile.reducer';
import {
  IProfileOptionsResponse,
  IRotProfileModel
} from '../../../core/services/profile/interfaces';
import { ValidationTypesEnum } from '../../../core/services/employee/employee.service';

export const ActionTypes = {
  FETCH_RECRUIT_PROFILE: type('[Profile] fetch recruit profile'),
  STORE_RECRUIT_PROFILE: type('[Profile] set recruit profile'),
  PREPARE_PROFILE_FORM_DATA: type('[Profile] set form data'),
  PRE_POPULATE_PROFILE_FORM: type('[Profile] pre populate form'),
  PROFILE_SEARCH_SCHOOL: type('[Profile] search school'),
  PROFILE_UPDATE_FORM_DATA: type('[Profile] update form data'),
  FETCH_SELECT_OPTIONS: type('[Profile] fetch select options'),
  SET_SELECT_OPTIONS: type('[Profile] set select options'),
  SET_EDIT_MODE: type('[Profile] set edit mode'),
  PROFILE_SUBMIT_FORM_DATA: type('[Profile] submit profile info'),
  PROFILE_COMPLETE_SUBMISSION: type('[Profile] complete submission'),
  PROFILE_SEARCH_STATE: type('[Profile] search state'),
  SET_FIELD_LOADING_STATE: type('[Profile] set field loading state'),
  VALIDATE_PHONE: type('[Profile] validate phone'),
  SET_PHONE_VALIDATION: type('[Profile] set phone validation'),
  INITIAL_PERSONAL_PHONE_VALIDATION: type('[Profile] initial personal phone validation'),
  INITIAL_EMERGENCY_PHONE_VALIDATION: type('[Profile] initial emergency phone validation'),
  SUBMIT_EMERGENCY_CONTACTS: type('[Profile] submit emergency contacts'),
  CANCEL_CHANGES: type('[Profile] cancel changes'),
};

export class FetchRecruitProfile implements Action {
  public type = ActionTypes.FETCH_RECRUIT_PROFILE;

  constructor(public payload?: any) {}
}

export class StoreRecruitProfile implements Action {
  public type = ActionTypes.STORE_RECRUIT_PROFILE;

  constructor(public payload: IRotProfileModel) {}
}

export class PrepareProfileFormData implements Action {
  public type = ActionTypes.PREPARE_PROFILE_FORM_DATA;

  constructor(public payload: IRotProfileModel) {}
}

export class PrepopulateProfileForm implements Action {
  public type = ActionTypes.PRE_POPULATE_PROFILE_FORM;

  constructor(public payload: IRecruitProfileFormData) {}
}

export class SearchSchool implements Action {
  public type = ActionTypes.PROFILE_SEARCH_SCHOOL;

  constructor(public payload: string) {}
}

export class FormInputUpdate implements Action {
  public type = ActionTypes.PROFILE_UPDATE_FORM_DATA;

  constructor(public payload: {
    fieldId: string,
    value: any
  }) {}
}

export class FetchSelectOptions implements Action {
  public type = ActionTypes.FETCH_SELECT_OPTIONS;

  constructor(public payload?: any) {}
}

export class StoreSelectOptions implements Action {
  public type = ActionTypes.SET_SELECT_OPTIONS;

  constructor(public payload: IProfileOptionsResponse) {}
}

export class SetEditMode implements Action {
  public type = ActionTypes.SET_EDIT_MODE;

  constructor(public payload: {
    group: ProfileEditModes,
    state: boolean
  }) {}
}

export class SubmitProfileInfo implements Action {
  public type = ActionTypes.PROFILE_SUBMIT_FORM_DATA;

  constructor(public payload?: any) {}
}

export class CompleteSubmission implements Action {
  public type = ActionTypes.PROFILE_COMPLETE_SUBMISSION;

  constructor(public payload: ProfileEditModes) {}
}

export class ProfileSearchState implements Action {
  public type = ActionTypes.PROFILE_SEARCH_STATE;

  constructor(public payload: string) {}
}

export class SetFieldLoadingState implements Action {
  public type = ActionTypes.SET_FIELD_LOADING_STATE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    isLoading: boolean
  }) {
  }
}

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {
  }
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {
  }
}

export class InitialPersonalPhoneValidation implements Action {
  public type = ActionTypes.INITIAL_PERSONAL_PHONE_VALIDATION;

  constructor(public payload?: any) {
  }
}

export class InitialEmergencyPhoneValidation implements Action {
  public type = ActionTypes.INITIAL_EMERGENCY_PHONE_VALIDATION;

  constructor(public payload?: any) {
  }
}

export class SubmitEmergencyContacts implements Action {
  public type = ActionTypes.SUBMIT_EMERGENCY_CONTACTS;

  constructor(public payload?: any) {
  }
}

export class CancelProfileUpdate implements Action {
  public type = ActionTypes.CANCEL_CHANGES;

  constructor(public payload: ProfileEditModes) {
  }
}

export type TypeAction =
  FetchRecruitProfile |
  StoreRecruitProfile |
  PrepareProfileFormData |
  PrepopulateProfileForm |
  SearchSchool |
  FormInputUpdate |
  FetchSelectOptions |
  StoreSelectOptions |
  SetEditMode |
  InitialPersonalPhoneValidation |
  InitialEmergencyPhoneValidation |
  SubmitProfileInfo |
  CompleteSubmission |
  ProfileSearchState |
  SubmitEmergencyContacts |
  CancelProfileUpdate |
  SetPhoneValidation |
  ValidatePhone |
  SetFieldLoadingState;
